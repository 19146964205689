import { SubscriptionTier } from '@invoice-simple/common';

import { getReferralData } from 'src/apis/friendbuyAPI';
import UserModel from 'src/models/UserModel';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { getPaywallCouponInfo } from '../SubscriptionPaywall/utils';

type AdvocateAction = 'share-url';
type AdvocateActionSource = 'invoices';

export const shouldShowReferralModal = (): boolean => {
  const queryParam = getURLQueryParam(URLQueryParamKeys.REF);
  if (!queryParam || queryParam !== 'referral-signup') return false;

  const coupon = getPaywallCouponInfo();
  if (coupon?.name !== 'referral50off3months') return false;

  return true;
};

export const shouldShowReferralPurchaseModal = (): boolean => {
  const user = UserModel.getInstance();

  const queryParam = getURLQueryParam(URLQueryParamKeys.REF);

  if (
    queryParam === URLQueryParamKeys.REFERRAL_PURCHASE &&
    !!user.advocateReferralCode &&
    user.subscriptionTier !== SubscriptionTier.ESSENTIALS
  ) {
    return true;
  }

  return false;
};

export const trackAdvocateAction = (
  action: AdvocateAction,
  data: { source: AdvocateActionSource }
) => {
  const user = UserModel.getInstance();

  user.events.trackAction('advocate-action', { platform: 'web', action, ...data });
};

export const getAdvocateReferralCode = async (user: UserModel, forceFetch?: boolean) => {
  const localStorageReferredCode = localStorage.getItem('advocateReferralCode');

  if (localStorageReferredCode && !forceFetch) {
    return localStorageReferredCode === 'null' ? null : localStorageReferredCode;
  }

  const referralData = await getReferralData(user);
  const referredCode = referralData?.advocateReferralCode || 'null';

  localStorage.setItem('advocateReferralCode', referredCode);

  return referredCode === 'null' ? null : referredCode;
};
