import React, { useEffect } from 'react';

import { defineMessages } from 'react-intl';

import { trackEvent } from 'src/analytics/controller';
import { useISIntl } from 'src/i18n';
import { PaymentsOrderListModel } from 'src/models/PaymentsOrderListModel';
import { usePaymentsDashboardEligibility } from 'src/payments/hooks/usePaymentsDashboardEligibility';
import { useShouldRedirectToLandingPage } from 'src/payments/hooks/useShouldRedirectToLandingPage';
import { PaymentEventComponent } from 'src/payments/types';
import { navigateHard, redirectToPaymentsLandingPageWithEvents } from 'src/payments/utils';
import {
  PaymentsDashboardOnboarded,
  PaymentsDashboardOnboarding,
  PaymentsDashboardRejected,
  PaymentsDashboardRestricted
} from './Statuses';

const messages = defineMessages({
  setUpPayments: {
    id: 'paymentsDashboard.setUpPayments',
    defaultMessage: 'Set Up Payments'
  },
  getPaidFaster: {
    id: 'paymentsDashboard.getPaidFaster',
    defaultMessage: 'Get Paid Faster'
  },
  receivePayments: {
    id: 'paymentsDashboard.receivePayments',
    defaultMessage:
      'Invoice Simple Payments is a hassle-free way to receive payments from your clients.'
  },
  finishSetUpPayments: {
    id: 'paymentsDashboard.finishSetUpPayments',
    defaultMessage: 'Finish Set Up'
  },
  finishSettingUpPayments: {
    id: 'paymentsDashboard.finishSettingUpPayments',
    defaultMessage: 'Finish Setting up Payments'
  },
  almostReady: {
    id: 'paymentsDashboard.almostReady',
    defaultMessage: "You're almost ready to accept online payments and get paid twice as fast!"
  }
});

export const PaymentsDashboard = (props: {
  dateFormat: string;
  paymentsOrderList: PaymentsOrderListModel;
  onError: (error: { title?: string; body?: string }) => void;
}) => {
  const { fta, ft } = useISIntl();
  useEffect(() => {
    trackEvent('payments-screen', {
      screenAction: 'shown',
      screen: 'payments-dashboard'
    });
  }, []);

  const paymentsDashboardEligibility = usePaymentsDashboardEligibility();
  const shouldRedirectToLandingPage = useShouldRedirectToLandingPage();

  if (paymentsDashboardEligibility.paymentsTabHref === '/paypal/learnmore') {
    navigateHard(paymentsDashboardEligibility.paymentsTabHref);
    return null;
  }

  if (shouldRedirectToLandingPage) {
    redirectToPaymentsLandingPageWithEvents();
    return null;
  }

  if (paymentsDashboardEligibility.eligible.length === 0) {
    return null;
  }

  if (paymentsDashboardEligibility.accepting.length > 0) {
    return <PaymentsDashboardOnboarded {...props} />;
  }

  if (paymentsDashboardEligibility.rejected.length > 0) {
    return <PaymentsDashboardRejected />;
  }

  if (paymentsDashboardEligibility.restricted.length > 0) {
    return <PaymentsDashboardRestricted />;
  }

  if (paymentsDashboardEligibility.pending.length > 0) {
    return (
      <PaymentsDashboardOnboarding
        ctaButtonText={fta(messages.finishSetUpPayments)}
        titleText={fta(messages.finishSettingUpPayments)}
        subtitleText={ft(messages.almostReady)}
        component={PaymentEventComponent.tilePending}
      />
    );
  }

  if (paymentsDashboardEligibility.initial.length > 0) {
    return (
      <PaymentsDashboardOnboarding
        ctaButtonText={fta(messages.setUpPayments)}
        titleText={fta(messages.getPaidFaster)}
        subtitleText={ft(messages.receivePayments)}
        component={PaymentEventComponent.tileInitial}
      />
    );
  }

  return null;
};
