import React, { useEffect } from 'react';

import { Platform, SettingKeys } from '@invoice-simple/common';

import { changeUser, openSession } from '@braze/web-sdk';

import './Icons/library';

import { inject, observer } from 'mobx-react';

import { trackAccountSource } from 'src/apis/accountAPI';
import { useAmplitudeSessionReplay } from 'src/components/Amplitude/hooks/use-amplitude';
import GlobalHelmet from 'src/components/GlobalHelmet';
import { NotificationPrompt, notificationsInstallModel } from 'src/components/Notifications';
import EstimateSeoContent from 'src/components/SeoContent/Estimate';
import InvoiceSeoContent from 'src/components/SeoContent/Invoice';
import ReceiptSeoContent from 'src/components/SeoContent/Receipt';
import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import { LocationModel } from 'src/models/LocationModel';
import { PaypalOnboardingLink } from 'src/payments';
import { PaymentsDashboard } from 'src/payments/components/PaymentsDashboard';
import { SubscriptionPurchaseTracker } from 'src/subs';
import { getCookie } from 'src/util/cookie';
import { compose } from 'src/util/functions';
import { trackImpactIdentifyEvent } from 'src/util/impact';
import { isMigratedEditorEnabled } from 'src/util/isMigratedEditorEnabled';
import { isOnboardingV1Enabled, shouldRedirectToOnboarding } from 'src/util/onboarding';
import alertModel from '../models/AlertModel';
import { AppStore } from '../stores/AppStore';
import { init } from '../util/braze';
import Alert from './Alert';
import { AppOverlay } from './AppOverlay/AppOverlay';
import { CancelSubscription } from './CancelSubscription';
import { SubscriptionCancelFlowVersion } from './CancelSubscription/common';
import ClientEdit from './ClientEdit';
import ClientList from './ClientList';
import DeleteAccount from './DeleteAccount';
import DevFooter from './DevFooter';
import { DocHistory } from './DocHistory';
import DocList from './DocList';
import Footer from './Footer';
import Invoice from './Invoice/';
import ItemEdit from './ItemEdit';
import ItemList from './ItemList';
import { Login } from './Login';
import { Navbar } from './Navbar';
import PasswordReset from './PasswordReset';
import { ReactivationPrompt } from './ReactivationPrompt';
import ReportList from './Reports';
import { RequestReviewRedirect } from './RequestReview/RequestReviewRedirect';
import SettingList from './Settings/SettingList';
import { Signup, SignupSms } from './Signup';
import Subscription from './Subscription';
import { TermsAcknowledgement } from './TermsAcknowledgement';
import TrialAlert from './TrialAlert';
import { PremiumUpgrade } from './Upgrades';

const messages = defineMessages({
  alertClientMissingTitle: {
    id: 'app.alert.client.missing.title',
    defaultMessage: 'Unable to find that Client'
  },
  alertClientDeletedTitle: {
    id: 'app.alert.client.deleted.title',
    defaultMessage: 'Client {clientName} deleted'
  },
  alertClientListFailedTitle: {
    id: 'app.alert.client.list.failed.title',
    defaultMessage: 'Unable to load client list'
  },
  alertItemMissingTitle: {
    id: 'app.alert.item.missing.title',
    defaultMessage: 'Unable to find that Item'
  },
  alertItemDeletedTitle: {
    id: 'app.alert.item.deleted.title',
    defaultMessage: 'Item {itemCode} deleted'
  },
  alertItemListFailedTitle: {
    id: 'app.alert.item.list.failed.title',
    defaultMessage: 'Unable to load item list'
  },
  alertInvoiceListFailedTitle: {
    id: 'app.alert.invoice.list.failed.title',
    defaultMessage: 'Unable to load invoice list'
  },
  alertEstimateListFailedTitle: {
    id: 'app.alert.estimate.list.failed.title',
    defaultMessage: 'Unable to load estimate list'
  },
  alertReportListFailedTitle: {
    id: 'app.alert.report.list.failed.title',
    defaultMessage: 'Unable to load report list'
  }
});

interface Props {
  location: LocationModel;
  store: AppStore;
}

const App = ({ store, location }: Props) => {
  const intl = useISIntl();
  const { formatMessage } = intl;
  const user = store.user;
  const onError = ({ title, body }: { title?: string; body?: string }) => {
    alertModel.setAlert('danger', title, body);
  };
  const [brazeLoaded, setBrazeLoaded] = React.useState(false);

  useEffect(() => {
    user.events.trackPageView(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    init({
      safariWebsitePushId: 'getinvoicesimple.com',
      allowUserSuppliedJavascript: true
    });
    setBrazeLoaded(true);
    trackImpactIdentifyEvent({ userId: user.id, email: user.email });
    trackAccountSource(user);
  }, []);

  useEffect(() => {
    if (brazeLoaded && user.id) {
      changeUser(user.id);
      openSession();
    }
  }, [brazeLoaded, user.id]);

  useAmplitudeSessionReplay(user.sessionToken);

  const hideTermsModal = location.name.match(/signup|requestReviewRedirect|subscriptionCancelV2/i);
  const isGuest = store.user.isGuest;
  const shouldShowNavbar =
    !location.isReviewRedirect &&
    location.name.match(
      /create|edit|delete|list|view|email|invoicepayment|upgrade|history|dashboard/i
    );

  const onboardingV1Enabled = isOnboardingV1Enabled();

  if (shouldRedirectToOnboarding()) {
    const lastPage = getCookie('lastOnboardingPage') ?? '/onboarding/business-details';
    location.navigateHard(lastPage);
    return;
  }

  const shouldHideAlert = onboardingV1Enabled && location.name.match(/signup|login/i);

  return (
    <div className="app">
      {!hideTermsModal && isGuest && <TermsAcknowledgement />}
      {store.isAppDisabled && <AppOverlay />}

      <GlobalHelmet user={user} />
      {user.isLoaded && <SubscriptionPurchaseTracker />}

      {user.isSubTrialActive &&
        !location.name.match(/subscription/) &&
        !location.name.match(/login/) && <TrialAlert store={store} />}

      {shouldShowNavbar && <Navbar />}

      {notificationsInstallModel.isShown && user.isSubPaid && <NotificationPrompt />}

      {!shouldHideAlert && <Alert shouldShowNavbar={!!shouldShowNavbar} />}

      {!onboardingV1Enabled && <div className="spacer"></div>}

      {/* reactivation prompt modal for churned users */}
      {!location.name.match(/signup|login|subscription/i) && user.isLoaded && (
        <ReactivationPrompt user={user} />
      )}

      {/* clients edit */}
      {location.name.match(/clientCreate|clientEdit/i) && (
        <ClientEdit
          list={user.clientList}
          onCancel={() => {
            store.nav('clientList');
          }}
          onLoad={() => {
            if (!location.id) {
              return user.clientList.createNewClient();
            }
            user.clientList.setClient(location.id).catch((err) => {
              store.nav('clientList');
              alertModel.setAlert(
                'danger',
                formatMessage(messages.alertClientMissingTitle),
                err.message
              );
            });
          }}
          onDeleted={(client) => {
            store.nav('clientList');
            alertModel.setAlert(
              'success',
              formatMessage(messages.alertClientDeletedTitle, { clientName: client.name })
            );
          }}
        />
      )}

      {/* items edit */}
      {location.name.match(/itemCreate|itemEdit/i) && (
        <ItemEdit
          list={user.itemList}
          onCancel={(item) => {
            store.nav('itemList');
            if (!item.isFresh && item.isUpdated) {
              user.trackAppEventViaApi('item-update', { platform: Platform.WEB });
            }
          }}
          onLoad={() => {
            if (!location.id) {
              return user.itemList.createNewItem();
            }
            user.itemList.setItem(location.id).catch((err) => {
              store.nav('itemList');
              alertModel.setAlert(
                'danger',
                formatMessage(messages.alertItemMissingTitle),
                err.message
              );
            });
          }}
          onDeleted={(item) => {
            store.nav('itemList');
            alertModel.setAlert(
              'success',
              formatMessage(messages.alertItemDeletedTitle, { itemCode: item.code })
            );
            user.trackAppEventViaApi('item-delete', { platform: Platform.WEB });
          }}
        />
      )}

      {/* clients */}
      {location.name.match(/clientList/i) && (
        <ClientList
          onNav={(n: string, opts?: any) => {
            store.nav(n, opts);
          }}
          onError={onError}
          list={user.clientList}
        />
      )}

      {/* items */}
      {location.name.match(/itemList/i) && (
        <ItemList
          onNav={(n: string, opts?: any) => {
            store.nav(n, opts);
          }}
          onError={onError}
          list={user.itemList}
        />
      )}

      {/* invoices */}
      {location.name.match(/invoiceList/i) && (
        <DocList
          list={user.invoiceList}
          onCreate={() => {
            if (isMigratedEditorEnabled(user.countryCode)) {
              return window.location.assign(`${process.env.REACT_APP_URL}/invoice/new`);
            }
            store.navAndScrollTop('invoiceCreate');
          }}
          onView={(id?: string) => {
            if (isMigratedEditorEnabled(user.countryCode)) {
              return window.location.assign(`${process.env.REACT_APP_URL}/invoice/${id}`);
            }
            store.navAndScrollTop('invoiceView', { id });
          }}
          onLoad={() => {
            user.trackAppEventViaApi('invoice-list-view');
          }}
          onError={onError}
        />
      )}

      {/* estimates */}
      {location.name.match(/estimateList/i) && (
        <DocList
          list={user.estimateList}
          onCreate={() => {
            if (isMigratedEditorEnabled(user.countryCode)) {
              return window.location.assign(`${process.env.REACT_APP_URL}/estimate/new`);
            }
            store.navAndScrollTop('estimateCreate');
          }}
          onView={(id?: string) => {
            if (isMigratedEditorEnabled(user.countryCode)) {
              return window.location.assign(`${process.env.REACT_APP_URL}/estimate/${id}`);
            }
            store.navAndScrollTop('estimateView', { id });
          }}
          onLoad={() => {
            try {
              user.trackAppEventViaApi('estimate-list-view');
            } catch (error) {
              alertModel.setAlert(
                'danger',
                formatMessage(messages.alertEstimateListFailedTitle),
                error.message
              );
            }
          }}
          onError={onError}
        />
      )}

      {/* settings */}
      {location.name.match(/settingList/i) && (
        <SettingList
          store={store}
          list={user.settingList}
          onLoad={() => {
            user.trackAppEventViaApi('setting-list-view');
          }}
        />
      )}

      {location.isSubscription && (
        <Subscription
          store={store}
          onLoad={() => {
            user.trackAppEventViaApi('subscription-view');
          }}
          intl={intl}
        />
      )}

      {location.isPrivateDoc && <Invoice />}

      {/* First time user lands here */}
      {location.isGuestDoc && <Invoice />}

      {/* signup */}
      {location.name.match(/\bsignup\b/i) && <Signup />}

      {/* sms signup */}
      {location.name.match(/signupSms/i) && <SignupSms />}

      {/* login */}
      {location.name.match(/login/i) && <Login />}

      {/* premium upgrade */}
      {location.name.match(/\bupgrade\b/i) && (
        <PremiumUpgrade
          store={store}
          onError={onError}
          onLoad={() => {
            user.trackAppEventViaApi('upgrade-web-mobile-paywall');
          }}
        />
      )}

      {/* PayPal landing page */}
      {location.name.match(/learnmore/i) && (
        <PaypalOnboardingLink
          path="learnmore"
          onError={onError}
          onTrackEvent={(eventName) => user.trackAppEventViaApi(eventName)}
        />
      )}

      {/* PayPal onboarding page */}
      {location.name.match(/onboarding/i) && (
        <PaypalOnboardingLink
          path="onboarding"
          onError={onError}
          onTrackEvent={(eventName) => user.trackAppEventViaApi(eventName)}
        />
      )}

      {/* paypal dashboard */}
      {location.name.match(/paymentsDashboard/i) && (
        <PaymentsDashboard
          onError={onError}
          paymentsOrderList={user.paymentsOrderListModel}
          dateFormat={user.settingList.getSetting(SettingKeys.LocaleDateFormat)?.value || 'PP'}
        />
      )}

      {/* reports */}
      {location.name.match(/reportList/i) && (
        <ReportList
          list={user.reportList}
          store={store}
          onCreate={() => {
            store.navAndScrollTop('invoiceCreate');
          }}
          onLoad={async () => {
            try {
              await user.reportList.load();
            } catch (err) {
              alertModel.setAlert(
                'danger',
                formatMessage(messages.alertReportListFailedTitle),
                err.message
              );
            }
          }}
        />
      )}
      {/* delete */}
      {location.name.match(/accountDelete/i) && <DeleteAccount store={store} />}

      {/* password */}
      {location.name.match(/password/i) && <PasswordReset store={store} focusTheme={true} />}

      {/* cancel sub */}
      {location.isCancelSubscriptionPage && (
        <CancelSubscription
          version={SubscriptionCancelFlowVersion.V1}
          user={store.user}
          onLoad={() => {}}
          onError={onError}
        />
      )}
      {location.isCancelSubscriptionPageV2 && (
        <CancelSubscription
          version={SubscriptionCancelFlowVersion.V2}
          user={store.user}
          onLoad={() => {}}
          onError={onError}
        />
      )}

      {/* review url redirect */}
      {location.name.match(/requestReviewRedirect/i) && <RequestReviewRedirect />}

      {location.isDocHistory && <DocHistory store={store} />}

      {/* seo content */}
      {location.isGuestDoc && location.isReceipt && <ReceiptSeoContent />}
      {location.isGuestDoc && location.isEstimate && <EstimateSeoContent />}
      {location.isGuestDoc && location.isInvoice && <InvoiceSeoContent />}

      {/* seo footer */}
      {location.isGuestDoc && <Footer />}

      {/* dev footer */}
      {<DevFooter />}
    </div>
  );
};

export default compose(inject('store', 'location'), observer)(App);
