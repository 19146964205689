import { useEffect, useState } from 'react';

import Parse from 'parse';

import * as sessionReplay from '@amplitude/session-replay-browser';

import Rollbar from 'src/analytics/rollbar';
import { getCookie, setCookie } from 'src/util/cookie';
import environmentStore from '../../../stores/EnvironmentStore';

const API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY as string;
const isProduction = process.env.NODE_ENV === 'production';

/*
  Custom hook to initialize Amplitude's Session Replay.
  - Initialize Amplitude if not initialized yet.
  - If there is a new session token, get the new session creation date and use it as the session ID.
*/
export function useAmplitudeSessionReplay(extSessionToken: string) {
  const [sessionToken, setSessionToken] = useState<string>();
  const [amplitudeLoaded, setAmplitudeLoaded] = useState(false);

  useEffect(() => {
    const initAmplitude = async () => {
      if (!amplitudeLoaded) {
        const session = await Parse.Session.current();
        init(session.createdAt.getTime(), environmentStore.installationId);

        setAmplitudeLoaded(true);
        setSessionToken(extSessionToken);
        setCookie('sessionId', session.createdAt.getTime());
      }
    };

    initAmplitude();
  }, []);

  useEffect(() => {
    const updateAmplitude = async () => {
      if (amplitudeLoaded && extSessionToken !== sessionToken) {
        const session = await Parse.Session.current();
        const sessionId = session.createdAt.getTime();

        sessionReplay.setSessionId(sessionId);
        setCookie('sessionId', sessionId);
        setSessionToken(extSessionToken);
      }
    };

    updateAmplitude();
  }, [amplitudeLoaded, extSessionToken]);
}

/*
  Initialize Amplitudes Session Replay with the provided session ID and device ID.
  - Amplitude uses the session ID and device ID to create a unique session replay for each user session.
  - The sample rate is set to 10% in production, for development is set to 0%, change if you desire to test sessions replay.
  - To debug session replay, add 'debugMode: true' and 'logLevel: 4' to enable log messages in the browser console.
*/
const init = (sessionId: number, deviceId: string) => {
  try {
    sessionReplay.init(API_KEY, {
      deviceId: deviceId,
      sessionId: sessionId,
      sampleRate: isProduction ? 0.1 : 1
    });
  } catch (error) {
    Rollbar.trackError('Error initializing Amplitude', error);
  }
};

export const getAmplitudeSessionId = (): number | undefined => {
  const sessionId = sessionReplay.getSessionId();

  if (sessionId) {
    return sessionId;
  }

  return getCookie('sessionId') as number | undefined;
};
