import { getUser, User } from '@braze/web-sdk';

import { saveSignUpAccountConsentData } from 'src/apis/signupAPI';
import AlertModel from 'src/models/AlertModel';
import UserModel from 'src/models/UserModel';
import { messages } from './messages';

export const handleConsent = async (consent: boolean) => {
  const user = UserModel.getInstance();

  const message = `${messages.consentVisible.defaultMessage} ${messages.consentHidden.defaultMessage
    .replace('<privacyUrl>', '')
    .replace('</privacyUrl>', '')}`;

  await saveSignUpAccountConsentData({ consent, message, user });

  if (consent) {
    getUser()?.setEmailNotificationSubscriptionType(User.NotificationSubscriptionTypes.OPTED_IN);
  }
};

export const validateReferralSignup = async (user: UserModel, referralCode: string) => {
  await user.syncReferrals(true);

  if (user.advocateReferralCode === referralCode) {
    return AlertModel.setAlertObject({
      type: 'warning',
      titleMessageId: 'earnReferralBonus'
    });
  }

  return AlertModel.setAlertObject({
    type: 'warning',
    titleMessageId: 'invalidReferralLink'
  });
};
