import React from 'react';

import UserModel from 'src/models/UserModel';
import { redirectToPaymentsLandingPageWithEvents } from 'src/payments';
import { usePaymentsDashboardEligibility } from 'src/payments/hooks/usePaymentsDashboardEligibility';
import { useShouldRedirectToLandingPage } from 'src/payments/hooks/useShouldRedirectToLandingPage';
import NavItem from './NavItem';

interface Props {
  isActive: boolean;
  isDisabled: boolean;
}

const PaymentsNavItem = ({ ...rest }: Props) => {
  const paymentsDashboardEligibility = usePaymentsDashboardEligibility();

  const shouldRedirectToLandingPage = useShouldRedirectToLandingPage();

  if (!paymentsDashboardEligibility.paymentsTabHref) {
    return null;
  }

  const onClickHandler = () => {
    UserModel.getInstance().trackAppEventViaApi('paypal-navItem-clicked');

    if (shouldRedirectToLandingPage) {
      redirectToPaymentsLandingPageWithEvents();
    } else if (paymentsDashboardEligibility.paymentsTabHref) {
      window.location.assign(paymentsDashboardEligibility.paymentsTabHref);
    }
  };

  return (
    <NavItem
      style={{ cursor: 'pointer' }}
      data-testid="payments-nav-item"
      onClick={onClickHandler}
      href={'/payments/dashboard'}
      {...rest}>
      Payments
    </NavItem>
  );
};

export default PaymentsNavItem;
