import { Platform } from '@invoice-simple/common';
import { PaywallCouponConfig } from '@invoice-simple/is-coupon';

import UserModel from 'src/models/UserModel';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { API_V3_BASE_PATH, client } from './httpClient';

export const COUPONS_ENDPOINT = `${API_V3_BASE_PATH}/coupons`;

export const getCouponInfo = async (user: UserModel): Promise<PaywallCouponConfig | undefined> => {
  const { headers } = user.getApiV3ReqOpts();
  const couponQueryParam = getURLQueryParam(URLQueryParamKeys.COUPON);

  const body = {
    platform: Platform.WEB,
    ...(couponQueryParam ? { coupon: couponQueryParam } : {})
  };

  const result = await client.post(COUPONS_ENDPOINT, body, {
    headers
  });

  return result.data;
};
