import { useFlags } from 'flagsmith/react';

import { usePaypalContext, useStripeContext } from '../contexts';

export const useShouldRedirectToLandingPage = () => {
  const { payments_enabled, paypal_redesign_web, paypal_v_stripe_variant } = useFlags([
    'payments_enabled',
    'paypal_redesign_web',
    'paypal_v_stripe_variant'
  ]);

  if (!payments_enabled?.enabled || !paypal_redesign_web?.enabled) {
    return false;
  }

  const stripeAccountContext = useStripeContext();
  const paypalAccountContext = usePaypalContext();

  const stripeNotEligible = !(
    paypal_v_stripe_variant.enabled && !!stripeAccountContext?.isStripeEligible
  );
  const isStripeInitial =
    paypal_v_stripe_variant.enabled && !!stripeAccountContext?.isStripeInitial;
  const isPaypalInitial = !!paypalAccountContext?.isPaypalInitial();

  const paypalAndStripeInitial = isStripeInitial && isPaypalInitial;
  const paypalOnly = stripeNotEligible && isPaypalInitial;

  return paypalAndStripeInitial || paypalOnly;
};
